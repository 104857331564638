import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"

const error = () => {
  return (
    <>
      <Seo title="Error" />
      <Layout>
        <BannerModule small={true} />
        <div className="container container__tight">
          <div className="section">
            <h1>OOPS.</h1>
            <h2>This page has moved or no longer exists.</h2>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default error
